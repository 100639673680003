//
// user.scss
// Use this to write your custom SCSS
//

.nav-pills .nav-link:hover {
    text-decoration: underline;
}

.top-search-bar {
    .form-control {
        width: 260px;
        &:focus {
            border-color: #2C7BE5;
        }
    }
    .input-group > * {
        background-color: #1E3A5C;
        border-color: #1E3A5C;
    }
    .search-results {
        padding: 1rem;
        margin-top: 1.25rem;
        width: 300px;
        max-height: 75vh;
        z-index: $zindex-tooltip;
    }
    .search-result:hover {
        background-color: #EDF2F9;
    }
}

#js-webform {
    ::-webkit-scrollbar {
        width: 6px;
    }
    ::-webkit-scrollbar-track {
        border-radius: 3px;
    }
    ::-webkit-scrollbar-thumb {
        background: #999;
        border-radius: 3px;
    }
    ::-webkit-scrollbar-thumb:hover {
        background: #555;
    }
}

$header-height: 100px;
$header-margin: 1.5rem;

#webformSubheader.sticky {
    position: sticky;
    top: 0;
    z-index: $zindex-sticky;
    border-bottom: $border-width solid $border-color;
    .show-sticky {
        display: block !important;
    }
    .hide-sticky {
        display: none !important;
    }
}

#webformSidenav {
    width: 250px;
    max-height: calc(100vh - #{$header-height} - #{$header-margin});
    top: calc(#{$header-height} + #{$header-margin});
    scrollbar-width: thin;
    scrollbar-color: #999 $body-bg;
    .nav-link.active {
        color: $dark !important;
    }
}

.scroll-element {
    scroll-margin-top: calc(#{$header-height} + #{$header-margin});
}

.btn-collapse {
    .fe {
        transition: transform 0.2s ease-in-out;
    }
    &.collapsed .fe-chevron-up {
        transform: rotate(180deg);
    }
    &.collapsed .fe-chevron-down {
        transform: rotate(-90deg);
    }
}

.bg-whitesmoke {
    background-color: $whitesmoke;
}

.img-placeholder {
    width: 5rem;
    height: 5rem;
    background-color: #D9D9D9;
}

.img-placeholder-sm {
    width: 2.5rem;
    height: 2.5rem;
    background-color: #D9D9D9;
}

.table-fixed {
    table-layout: fixed;
}

.link-default {
    @extend .link-dark;
    @extend .text-decoration-underline;
}

.btn-link-default {
    @extend .text-decoration-underline;
    @extend .p-0;
}

.btn-translucent {
    opacity: 0.85;
}

.feedback-form {
    width: 22vw;
    &::after {
        content: " ";
        position: absolute;
        top: 50%;
        right: 100%;
        margin-top: -10px;
        border-width: 10px;
        border-style: solid;
        border-color: transparent $white transparent transparent;
    }
}

.dropup-menu-end {
    width: 300px;
    &::after {
        content: " ";
        position: absolute;
        top: 100%;
        right: 50px;
        margin-right: -10px;
        border-width: 10px;
        border-style: solid;
        border-color: $white transparent transparent transparent;
    }
}

.tooltip-container {
    position: relative;
    &:hover .tooltip-text {
        visibility: visible;
    }
    .tooltip-text {
        visibility: hidden;
        position: absolute;
        width: max-content;
        max-width: 400px;
        bottom: 100%;
        left: 50%;
        transform: translateX(-50%);
        z-index: $zindex-tooltip;
        font-size: $small-font-size;
        padding: 5px 10px;
        margin-bottom: 5px;
        background-color: $dark;
        color: $white;
        &::after {
            content: " ";
            position: absolute;
            top: 100%;
            left: 50%;
            margin-left: -5px;
            border-width: 5px;
            border-style: solid;
            border-color: $dark transparent transparent transparent;
        }
        * {
            margin-bottom: 0;
        }
    }
    .tooltip-light {
        background-color: $aliceblue;
        color: $dark;
        &::after {
            border-color: $aliceblue transparent transparent transparent;
        }
    }
    .tooltip-lg::after {
        margin-left: -10px;
        border-width: 10px;
    }
}

.table-default-hover > tbody > tr:hover {
    background-color: $whitesmoke;
    .hover-visible {
        visibility: visible !important;
    }
}

.zindex-dropdown {
    z-index: $zindex-dropdown;
}

.zindex-fixed {
    z-index: $zindex-fixed;
}

.zindex-tooltip {
    z-index: $zindex-tooltip;
}

.stepper-sm {
    width: 1rem;
    height: 1rem;
}

.stepper {
    width: 1.25rem;
    height: 1.25rem;
}

.text-bg-success {
    background-color: #E1EFE9;
    color: #009054;
}

.text-bg-error {
    background-color: #f1bfbb;
    color: #860b02;
}

.line-clamp {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.line-clamp-2 {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.overflow-y-auto {
    overflow-y: auto;
    overflow-x: hidden;
}

.w-max-content {
    width: max-content;
}

.draggable-screenshot-mirror {
    @extend .row;
    @extend .align-items-center;
    th {
        @extend .col-2;
        @extend .border-top-0;
    }
    td:nth-child(2) {
        @extend .col-6;
        @extend .border-top-0;
    }
    td:nth-child(3) {
        @extend .col-4;
        @extend .border-top-0;
    }
    background-color: unset !important;
}

.rich-text {
    a:visited {
        color: purple;
    }
}

.object-position-top {
    object-position: top;
}

.border-transparent {
    border-color: transparent !important;
}

.text-pre-line {
    white-space: pre-line;
}

.tinymce-border-0 .tox-tinymce {
    border: 0 !important;
}

.hoverable {
    &:hover {
        background-color: $whitesmoke;
    }
    &.active {
        background-color: #d5e5fa;
    }
}

.status {
    &_open,
    &_awaiting_response {
        background-color: #F9DCD3;
        color: #D23F11;
        border: 1px solid #D23F11;
    }
    &_assigned,
    &_data_confirmation {
        background-color: #CCE3F8;
        color: #0073DA;
        border: 1px solid #0073DA;
    }
    &_in_progress {
        background-color: #0073DA;
        color: #FFFFFF;
    }
    &_submitted,
    &_request_for_extension {
        background-color: #E7F6F8;
        color: #005966;
        border: 1px solid #005966;
    }
    &_in_qc {
        background-color: #005966;
        color: #FFFFFF;
    }
    &_completed {
        background-color: #008B45;
        color: #FFFFFF;
    }
    &_fail {
        background-color: #CE2F56;
        color: #FFFFFF;
    }
    &_client_inquiries {
        background-color: #EAE6FF;
        color: #5243AA;
        border: 1px solid #5243AA;
    }
    &_ready_to_resume {
        background-color: #654631;
        color: #FFFFFF;
    }
    &_cancelled {
        background-color: #FFCF22;
        color: #FFFFFF;
    }
    &_client_request {
        background-color: #FFEDDF;
        color: #654631;
        border: 1px solid #654631;
    }
    &_ci_others {
        background-color: #FFEEF266;
        color: #980000;
        border: 1px solid #980000;
    }
    &_ci_rush {
        background-color: #FFEEF266;
        color: #CE2F56;
        border: 1px solid #CE2F56;
    }
    &_default {
        background-color: #D2D9DF;
        color: #0F2A43;
    }
}

.border-dashed {
    border-style: dashed !important;
}

.border-gold {
    border-color: $gold !important;
}

.border-indigo {
    border-color: $indigo !important;
}

.border-teal {
    border-color: $teal !important;
}

.bg-gold {
    --bs-bg-opacity: 1;
    background-color: rgba($gold, var(--bs-bg-opacity)) !important;
}

.bg-indigo {
    --bs-bg-opacity: 1;
    background-color: rgba($indigo, var(--bs-bg-opacity)) !important;
}

.bg-teal {
    --bs-bg-opacity: 1;
    background-color: rgba($teal, var(--bs-bg-opacity)) !important;
}

.bg-orange {
    background-color: #D23F11;
}

.text-gold {
    color: $gold;
}

.text-indigo {
    color: $indigo;
}

.text-teal {
    color: $teal;
}

.bg-opacity-10 {
    // manually declaring this because somehow bootstrap's utility doesn't make it !important
    --bs-bg-opacity: .1 !important;
}
